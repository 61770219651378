import { useEffect } from 'react';

export default function BuyNow({ id, index, ui }) {

  useEffect(() => {
    const node = document.getElementById(`buy-now-${id}-${index}`);
    if (node) {
      if (node.querySelectorAll('iframe').length === 0) {
        const product = ui.createComponent('product', {
          id,
          node: node,
          moneyFormat: '£{{amount_no_decimals}}',
          options: {
            product: {
              iframe: false
            }
          }
        });
        console.log(product);
      }
    }
  }, [ id, index, ui ]);

  return <div className="buy-now__wrapper" id={`buy-now-${id}-${index}`} />;
}