import { useRef, useEffect } from 'react';
import { Text3D } from '@react-three/drei';
import univers from '../../assets/fonts/ltubasregheavy.json';
import * as THREE from 'three';
import lerp from '../utils/lerp';
import { useFrame } from '@react-three/fiber';
import { A11y } from '@react-three/a11y';
import { cockpitUrl } from '../utils/cockpit';

const PageLink = (props) => {

  const { perspexMaterial, position, scale, textPosition, textSize, linkText, linkPath, navigate, index, image, menuIsActive, setMenuIsActive } = props;
  const mesh = useRef({});
  const group = useRef({});
  const wrapper = useRef({});
  const text = useRef({});
  const textContent = useRef({});

  useEffect(() => {
    if (image?.path) {
      if (textContent.current) {
        new THREE.TextureLoader().load(
          cockpitUrl + '/' + image.path,
          (newTexture) => {
            newTexture.mapping = THREE.EquirectangularRefractionMapping;
            textContent.current.material.envMap = newTexture;
          }
        );
      }
    }
  }, [image]);
  
  useFrame(({ mouse, clock }) => {
    const { elapsedTime } = clock;
    let scale = 0;
    if (menuIsActive === true) {
      if (group.current) {
        const positionY = Math.sin(elapsedTime * 1.2 + index) * 0.12;
        group.current.position.y = positionY;
      }
      if (text.current?.rotation) {
        text.current.rotation.y = lerp(text.current.rotation.y, mouse.x * Math.PI * 0.125, 0.2);
        text.current.rotation.x = lerp(text.current.rotation.x, -mouse.y * Math.PI * 0.225, 0.1);
      }
      scale = lerp(wrapper.current.scale.z, 1, 0.1);
    } else {
      scale = lerp(wrapper.current.scale.z, 0, 0.06);
    }
    wrapper.current.scale.set(scale, scale, scale);
  });

  return (
    <group
      ref={wrapper}
      position={[...position]}
      scale={[0, 0, 0]}
    >
      <group
        scale={scale ? [scale, scale, scale] : [1, 1, 1]}
        ref={group}
      >
        <group ref={text}>
          <Text3D
            position={textPosition ? textPosition : [0, 0, 0]}
            size={textSize ? textSize : 1}
            ref={textContent}
            height={0.04}
            font={univers}
            material={new THREE.MeshPhysicalMaterial({
              color: 0xffffff,
              metalness: 0.9,
              roughness: 0,
              reflectivity: 0.99
            })}
          >{linkText}</Text3D>
        </group>
        <A11y behavior="button">
        <mesh
          ref={mesh}
            onClick={() => {
              if (menuIsActive === true) {
                navigate(linkPath);
                setMenuIsActive(false);
              }
            }}
        >
          <sphereGeometry args={[1, 24]} />
          <ambientLight />
          <primitive attach="material" object={perspexMaterial} />
          </mesh>
        </A11y>
      </group>
    </group>
  );
};

export default PageLink;