import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const PrivacyPolicy = (props) => {

  const { terms } = props;
  const { privacyPolicy } = terms;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`page page--privacyPolicy`}
    >
      <div className="container-fluid page__inner">
        <div className="row page__row">
          <h2 className={`col-xxs-12 page__heading--main`}>Privacy Policy</h2>
        </div>
        {
          privacyPolicy &&
          <div className="row">
            <div className="about__text">
              {parse(privacyPolicy)}
            </div>
          </div>
        }
      </div>
      <Footer {...props} />
    </motion.main>
  )
}

export default PrivacyPolicy;