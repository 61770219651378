import { useState } from 'react';
import Metaballs from './Metaballs';
import Logo3D from './Logo3D';
import TexturedMaterial from './TexturedMaterial';
import PerspexMaterial from './PerspexMaterial';
import { OrthographicCamera, Bounds } from '@react-three/drei';
import CustomGallery from './CustomGallery';
import { useEffect } from 'react';
import Menu3D from './Menu3d';

const HomepageScene = (props) => {

  const { windowWidth, windowHeight, pathname } = props;
  const [material, setMaterial] = useState({});
  const [envMap, setEnvMap] = useState({});
  const [perspexMaterial, setPerspexMaterial] = useState({});
  const [windowMin, setWindowMin] = useState(Math.min(windowWidth, windowHeight));
  const [windowMax, setWindowMax] = useState(Math.max(windowWidth, windowHeight));

  useEffect(() => {
    setWindowMin(Math.min(windowWidth, windowHeight));
    setWindowMax(Math.max(windowWidth, windowHeight));
  }, [windowWidth, windowHeight]);

  return (
    <group>
      <OrthographicCamera
        makeDefault={true}
        position={[0, 0, 10]}
        zoom={160}
      />
      <TexturedMaterial
        {...props}
        setMaterial={setMaterial}
        setEnvMap={setEnvMap}
      />
      <PerspexMaterial
        {...props}
        setPerspexMaterial={setPerspexMaterial}
      />
        {
          material.uuid &&
          <group
            scale={[
              Math.min(windowMin / windowMax, 0.8) * 1.5,
              Math.min(windowMin / windowMax, 0.8) * 1.5,
              Math.min(windowMin / windowMax, 0.8) * 1.5
            ]}
          >
            <Logo3D {...props} material={material} envMap={envMap} />
            <Metaballs
              {...props}
              material={perspexMaterial}
            />
            {
              perspexMaterial.uuid &&
              <Menu3D {...props} perspexMaterial={perspexMaterial} />
            }
          </group>
        }
        <directionalLight position={[0, 20, 20]} />
        {
          pathname === '/custom' &&
          <Bounds fit observe damping={6} margin={1.2}>
            <CustomGallery {...props} />
          </Bounds>
      }
    </group>
  )
};

export default HomepageScene;