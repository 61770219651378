import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment } from 'react';

const Header = (props) => {

  const { windowWidth, menuIsActive, setMenuIsActive } = props;

  return (
    <Fragment>
      <Link to="/" className="title__link" onClick={() => setMenuIsActive(false)}>
        <h1 className="title">Rifeandstride</h1>
      </Link>
      <AnimatePresence>
      {
        (windowWidth >= 500 ) &&
          <motion.nav
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            className="menu"
          >
          <Link
            className="menu__item font--heading"
            to={`/collections`}
            onClick={() => setMenuIsActive(false)}
          >Collections</Link>
          <Link
            className="menu__item font--heading"
            to={`/custom`}
            onClick={() => setMenuIsActive(false)}
          >Custom</Link>
          <Link
            className="menu__item font--heading"
            to={`/about`}
            onClick={() => setMenuIsActive(false)}
          >About</Link>
        </motion.nav>
      }
      </AnimatePresence>
      {
        windowWidth < 500 &&
        <button
          className={`menu-button${menuIsActive === true ? ' active' : ''}`}
          onClick={() => setMenuIsActive(!menuIsActive)}
          aria-label="menu"
        >{menuIsActive === true ? 'close' : 'menu'}</button>
      }
    </Fragment>
  )
}

export default Header;