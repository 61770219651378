import { Canvas } from '@react-three/fiber';
import HomepageScene from './HomepageScene';
// import FPSLimiter from '../utils/FPSLimiter';
import SceneResizer from '../utils/SceneResizer';

const HomepageSceneCanvas = (props) => {

  const { windowHeight } = props;

  return (
    <div
      className="canvas__wrapper"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <Canvas>
        {/* <FPSLimiter fps={40} /> */}
        <SceneResizer dpr={2} />
        <HomepageScene {...props} />
      </Canvas>
    </div>
  )
};

export default HomepageSceneCanvas;