import PageLink from './PageLink';

const Menu3D = (props) => {

  return (
    <group>
      <PageLink
        {...props}
        linkText={'CUSTOM'}
        position={[-0.23, 1.4, 4]}
        scale={0.9}
        index={32}
        textPosition={[-0.72, -0, 0]}
        textSize={0.22}
        linkPath={`/custom`}
        key="custom"
      />
      <PageLink
        {...props}
        linkText={'COLLECTIONS'}
        position={[0.2, 0, 4]}
        scale={1}
        index={14}
        textPosition={[-0.66, 0, 0]}
        textSize={0.14}
        linkPath={`/collections`}
        key="collections"
      />
      <PageLink
        {...props}
        linkText={'ABOUT'}
        position={[-0.2, -1.2, 4]}
        scale={0.8}
        index={63}
        textPosition={[-.56, -0.2, 0]}
        textSize={0.23}
        linkPath={`/about`}
        key="about"
      />
    </group>
  )
};

export default Menu3D;