import { useState } from 'react';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  const [year] = useState(new Date().getFullYear());

  return (
    <footer className={`footer container-fluid`}>
      <p>&copy; Rifeandstride {year} |{' '}
        <Link to="/terms-and-conditions">Terms and Conditions</Link> |{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>
      </p>
    </footer>
  )
}

export default Footer;