import { useEffect } from 'react';

const Preview = (props) => {

  const { setPreviewItem } = props;

  useEffect(() => {
    const handleReceiveMessage = (event) => {
      const { data } = event;
      if (data) {
        const { collection, entry } = data;
        if (collection) {
          setPreviewItem({ ...entry, collection: collection });
        }
      }
    }

    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage, false);
    }
  }, [setPreviewItem]);

  return null
}

export default Preview;