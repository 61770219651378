import { OrthographicCamera, Bounds } from '@react-three/drei';
import CustomGallery from './CustomGallery';

const GalleryScene = (props) => {

  return (
    <group>
      <OrthographicCamera
        makeDefault={true}
        position={[0, 0, 10]}
        zoom={160}
      />
        <directionalLight position={[0, 20, 20]} />
        <Bounds fit observe damping={6} margin={1.2}>
          <CustomGallery {...props} />
        </Bounds>
    </group>
  )
};

export default GalleryScene;