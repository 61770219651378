import { useRef, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';

const GracefulDegradation = (props) => {
  const three = useThree();
  const framesElapsed = useRef(0);

  useFrame(({ clock }) => {
    const { elapsedTime } = clock;
    framesElapsed.current += 1;

    if (framesElapsed.current / elapsedTime < 55 && elapsedTime > 5) {
      three.setDpr(Math.max(0.8, three.viewport.dpr * 0.8));
      framesElapsed.current = elapsedTime * 60;
    }
  });

  return (null)
}

const SceneResizer = (props) => {
  const three = useThree();
  const { dpr } = props;

  useEffect(() => {
    const onWindowResize = () => {
      if (!dpr) {
        if (three.size.width > 640) {
          if (three.viewport?.dpr !== 0.33) {
            three.setDpr(0.33)
          }
        } else {
          if (three.viewport?.dpr !== 0.5) {
            three.setDpr(0.5)
          }
        }
      } else {
        if (three.viewport?.dpr !== dpr) {
          three.setDpr(dpr);
        }
      }
    }

    onWindowResize();
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [three, dpr]);

  return (null)
}

export default SceneResizer;
export { GracefulDegradation };