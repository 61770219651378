import BuyNow from './BuyNow';

const Product = (props) => {

  const { product, index } = props;
  const { value } = product;
  const { productId } = value;
  
  return (
    <div className="col-xxs-12 col-sm-6 col-md-4 col-lg-3 page__item__embed__wrapper">
      {
        productId &&
        <BuyNow { ...props } id={ productId } index={ index } />  
      }
    </div>
  );
}

export default Product;