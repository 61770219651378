import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import LazyloadImage from '../components/LazyloadImage';

const Collections = (props) => {

  const { collections, isPreview } = props;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`page page--collection${isPreview === true ? ' page--preview' : ''}`}
    >
      <div className="container-fluid page__inner">
        <div className="row page__row">
          <h2 className={`col-xxs-12 page__heading--main`}>Collections</h2>
        </div>
        <div className="row page__row">
          {
            collections &&
            collections[0] &&
            collections.map(
              (collection, index) => (
                <div
                  className="col-xxs-12 col-xs-8 offset-xs-2 col-sm-6 offset-sm-0 col-md-4"
                  key={index}
                >
                  <Link
                    className="collections__thumbnail"
                    to={`/collection/${collection.name_slug}`}
                  >
                    <div className="collections__thumbnail__inner">
                      <div className="collections__thumbnail__content">
                        <LazyloadImage image={collection.mainImage} />
                        <h3>{collection.name}</h3>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            )
          }
        </div>
      </div>
      <Footer {...props} />
    </motion.main>
  )
}

export default Collections;