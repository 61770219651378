import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Content from './components/Content';
import { fetchCockpitData } from './utils/cockpit';
import * as _ from 'underscore';
import ShopifyBuy from '@shopify/buy-button-js';

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [userIsTouching, setUserIsTouching] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [collections, setCollections] = useState([]);
  const [contact, setContact] = useState({});
  const [custom, setCustom] = useState({});
  const [about, setAbout] = useState({});
  const [terms, setTerms] = useState({});
  const [options, setOptions] = useState({});
  const [materialProperties, setMaterialProperties] = useState({});
  const [previewItem, setPreviewItem] = useState({});
  const [shopifyClient, setShopifyClient] = useState({});
  const [ ui, setUI ] = useState({});
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [galleryImageIndex, setGalleryImageIndex] = useState(0);
  const [image, setImage] = useState({});

  useEffect(() => {
    const shopifyClientInstance = ShopifyBuy.buildClient({
      domain: '71b8be-2.myshopify.com',
      storefrontAccessToken: 'b58baeecb924e72b87a42587ec7068c4'
    });

    setShopifyClient(shopifyClientInstance);

    const uiInstance = ShopifyBuy.UI.init(shopifyClientInstance);
    setUI(uiInstance);
  }, []);

  useEffect(() => {
    fetchCockpitData('contact', setContact, false);
    const handleFetchMaterials = (data) => {
      if (data[0]) {
        setMaterialProperties(data[Math.floor(Math.random() * data.length)]);
      }
    }
    fetchCockpitData('custom', setCustom, false);
    fetchCockpitData('about', setAbout, false);
    fetchCockpitData('options', setOptions, false);
    fetchCockpitData('terms', setTerms, false);
    fetchCockpitData('collections', setCollections, true);
    fetchCockpitData('marchingCubesMaterials', handleFetchMaterials, true, null, { active: true });
  }, []);

  useEffect(() => {
    if (previewItem.collection === 'marchingCubesMaterials') {
      setMaterialProperties(previewItem);
    }
  }, [previewItem]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();

    const handleWindowResizeThrottled = _.debounce(handleWindowResize, 250);
    window.addEventListener('resize', handleWindowResizeThrottled);

    const handleTouchstart = () => {
      setUserIsTouching(true);
    }
    
    window.addEventListener('touchstart', handleTouchstart);

    const handleWheel = () => {
      setUserIsTouching(false);
    }
    
    window.addEventListener('wheel', handleWheel);


    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottled);
      window.removeEventListener('touchstart', handleTouchstart);
      window.removeEventListener('wheel', handleWheel);
    }
  }, []);

  return (
    <Content
      {...props}
      navigate={navigate}
      location={location}
      pathname={location.pathname}
      {...windowDimensions}
      collections={collections}
      setCollections={setCollections}
      custom={custom}
      terms={terms}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      contact={contact}
      about={about}
      options={options}
      materialProperties={materialProperties}
      shopifyClient={shopifyClient}
      ui={ui}
      menuIsActive={menuIsActive}
      setMenuIsActive={setMenuIsActive}
      userIsTouching={userIsTouching}
      galleryImageIndex={galleryImageIndex}
      setGalleryImageIndex={setGalleryImageIndex}
      image={image}
      setImage={setImage}
    />
  );
}

export default App;
