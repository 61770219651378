import { useEffect } from 'react';
import * as THREE from 'three';
import { cockpitUrl } from '../utils/cockpit';

const TexturedMaterial = (props) => {

  const { setMaterial, materialProperties, setEnvMap } = props;

  useEffect(() => {
    if (materialProperties._id) {
      let texturesLength = 0;
      let envMapExists = false;
      if (materialProperties.textures && typeof materialProperties.textures === 'object') {
        for (let texture of materialProperties.textures) {
          if (texture.value?.path && texture.value?.path !== '') {
            texturesLength++;
            if (texture.field.name === 'envMap') {
              envMapExists = true;
            }
          }
        }
      }
      if (envMapExists === false) {
        texturesLength++;
      }
      let texturesLoaded = 0;

      let color = [];
      if (materialProperties.color && materialProperties.color !== '') {
        for (let i = 0; i < 3; i++) {
          const colorChannel = materialProperties.color.split(',')[i];
          color.push(parseInt(colorChannel.replace('rgb(', '').replace('rgba(', '').replace(')')));
        }
      } else {
        color = [255, 255, 255];
      }

      const material = new THREE.MeshPhysicalMaterial({
        color: new THREE.Color(color[0] / 255, color[1] / 255, color[2] / 255),
        reflectivity: typeof materialProperties.reflectivity !== 'undefined' ? parseFloat(materialProperties.reflectivity) : 0,
        metalness: typeof materialProperties.metalness !== 'undefined' ? materialProperties.metalness : 0,
        roughness: typeof materialProperties.roughness !== 'undefined' ? materialProperties.roughness : 0,
        clearcoatRoughness: typeof materialProperties.clearcoatRoughness !== 'undefined' ? materialProperties.clearcoatRoughness : 0,
        bumpScale: typeof materialProperties.bumpScale !== 'undefined' ? materialProperties.bumpScale : 0,
        normalScale: typeof materialProperties.normalScale !== 'undefined' ? new THREE.Vector2(parseFloat(materialProperties.normalScale), parseFloat(materialProperties.normalScale)) : new THREE.Vector2(1, 1),
        displacementScale: typeof materialProperties.displacementScale !== 'undefined' ? materialProperties.displacementScale : 1,
        clearcoat: typeof materialProperties.clearcoat !== 'undefined' ? materialProperties.clearcoat : 0,
        side: THREE.DoubleSide
      });

      const { repeat } = materialProperties;
      const repeatNumber = parseFloat(repeat);

      const handleTextureLoaded = (texture, name) => {
        if (repeat && repeatNumber > 0) {
          texture.repeat.set(repeatNumber, repeatNumber);
        }

        if (name === 'map') {
          texture.encoding = THREE.sRGBEncoding;
        }

        texture.anisotropy = 4;
        if (name === 'envMap') {
          texture.mapping = THREE.EquirectangularReflectionMapping;
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          setEnvMap(texture);
        } else {
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
        }

        material[name] = texture;
        texturesLoaded++;
        if (texturesLoaded >= texturesLength - 1) {
          setMaterial(material);
        }
      }

      const loadTexture = (path, name) => {
        new THREE.TextureLoader().load(
          name !== 'envMap' ?
            cockpitUrl + '/storage/uploads' + path
            :
          path,
          (texture) => handleTextureLoaded(texture, name)
        );
      }

      if (texturesLength === 0) {
        setMaterial(material);
      } else {
        if (envMapExists === false) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 512;
          canvas.height = 512;
              
          const gradient = ctx.createLinearGradient(0, 0, 512, 0);

          // Add three color stops
          gradient.addColorStop(0, "white");
          gradient.addColorStop(0.25, "black");
          gradient.addColorStop(0.3, "white");
          gradient.addColorStop(0.5, "white");
          gradient.addColorStop(0.7, "white");
          gradient.addColorStop(0.75, "black");
          gradient.addColorStop(1, "white");

          // Set the fill style and draw a rectangle
          ctx.fillStyle = gradient;
          ctx.fillRect(0, 0, 512, 512);
          const dataUrl = canvas.toDataURL();
          loadTexture(dataUrl, 'envMap');
        }

        if (materialProperties.textures && typeof materialProperties.textures === 'object') {
          for (let texture of materialProperties.textures) {
            if (texture.value?.path) {
              loadTexture(texture.value.path, texture.field.name);
            }
          }
        }
      }
    }
  }, [setMaterial, materialProperties, materialProperties.color, setEnvMap]);

  return null;
};

export default TexturedMaterial;