import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import Product from '../components/Product';

const Collection = (props) => {

  const { collection, isPreview } = props;
  const { products } = collection;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`page page--collection${isPreview === true ? ' page--preview' : ''}`}
    >
      <div className="container-fluid page__inner">
        <div className="row page__row">
          <h2 className={`col-xxs-12 page__heading--main`}>Collection—{collection.name}</h2>
        </div>
        <div className="row products__row">
          {
            products &&
            products[0] &&
            products.map(
              (product, index) => (
                <Product {...props} product={product} key={index} index={index} />
              )
            )
          }
        </div>
      </div>
      <Footer {...props} />
    </motion.main>
  )
}

export default Collection;