import { motion } from 'framer-motion';
import GallerySceneCanvas from '../components/GallerySceneCanvas';

const Custom = (props) => {

  const { custom, galleryImageIndex, setGalleryImageIndex } = props;
  const { gallery } = custom;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`page page--custom`}
    >
      <GallerySceneCanvas {...props} />
      <button
        className={`page--custom__button page--custom__button--previous`}
        onClick={() => {
          if (galleryImageIndex > 0) {
            setGalleryImageIndex(galleryImageIndex - 1);
          } else {
            setGalleryImageIndex(gallery.length - 1);
          }
        }}
      >previous</button>
      <button
        className={`page--custom__button page--custom__button--next`}
        onClick={() => {
          if (galleryImageIndex < gallery.length - 1) {
            setGalleryImageIndex(galleryImageIndex + 1);
          } else {
            setGalleryImageIndex(0);
          }
        }}
      >next</button>
    </motion.main>
  )
}

export default Custom;