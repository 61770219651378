import { useRef, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import rModelPath from '../../assets/models/r.glb';
import sModelPath from '../../assets/models/s.glb';
import lerp from '../utils/lerp';
import { useFrame } from '@react-three/fiber';

const Logo3D = (props) => {

  const { material, pathname, menuIsActive, setMenuIsActive, windowWidth, windowHeight } = props;
  const rModel = useGLTF(rModelPath);
  const sModel = useGLTF(sModelPath);
  const group = useRef({});
  const r = useRef();
  const s = useRef();

  useFrame(({ mouse }) => {
    if (pathname === '/') {
      if (group.current?.rotation) {
        group.current.rotation.y = lerp(group.current.rotation.y, mouse.x * Math.PI * 0.3, 0.1);
        group.current.rotation.x = lerp(group.current.rotation.x, -mouse.y * Math.PI * 0.3, 0.1);
      }
      if (s.current && r.current) {
        if (menuIsActive === true) {
          s.current.position.x = lerp(s.current.position.x, 2, 0.005);
          r.current.position.x = lerp(r.current.position.x, -2, 0.005);
        } else {
          s.current.position.x = lerp(s.current.position.x, 0, 0.1);
          r.current.position.x = lerp(r.current.position.x, 0, 0.1);
        }
      }
    }
  });

  useEffect(() => {
    for (let logo of [rModel.scene, sModel.scene]) {
      if (logo) {
        logo.traverse(
          (child) => {
            if (child.isMesh) {
              child.material = material;
              child.material.displacementScale = 0;
            }
          }
        );
      }
    }
  }, [rModel.scene, sModel.scene, material]);

  const handleClick = () => {
    setMenuIsActive(true);
  }

  return (
    <group
      ref={group}
      scale={[windowWidth > windowHeight * 1.2 ? 32 : 18, windowWidth > windowHeight * 1.2 ? 32 : 18, windowWidth > windowHeight * 1.2 ? 32 : 18]}
      onClick={handleClick}
    >
      <mesh ref={r}>
        <primitive object={rModel.scene} />
      </mesh>
      <mesh ref={s}>
        <primitive object={sModel.scene} />
      </mesh>
    </group>
  )
};

export default Logo3D;