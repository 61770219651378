import { useRef, useState, useEffect } from 'react';
import { A11y } from '@react-three/a11y';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { cockpitUrl } from '../utils/cockpit';
import lerp from '../utils/lerp';

const CustomGalleryTexture = (props) => {

  const { image, setImageAspectRatio, texture, setTexture } = props;

  useEffect(() => {
    const img = document.createElement('img');
    const handleImgLoad = () => {
      const w = img.naturalWidth;
      const h = img.naturalHeight;
      setImageAspectRatio(img.naturalWidth / img.naturalHeight);
      
      const canvas = document.createElement('canvas');
      canvas.width = w > h ? 2048 : Math.floor(2048 / h * w);
      canvas.height = h > w ? 2048 : Math.floor(2048 / w * h);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL('image/jpeg', 30);
      setTexture(new THREE.TextureLoader().load(dataUrl));
    }

    img.crossOrigin = 'anonymous';
    img.addEventListener('load', handleImgLoad);
    if (image?.path && image?.path !== '') {
      img.src = cockpitUrl + image.path;
    }
    return () => {
      img.removeEventListener('load', handleImgLoad);
    }
  }, [image, setImageAspectRatio, setTexture]);

  useEffect(() => {
    if (texture.uuid) {
      texture.anisotropy = 4
    }
  }, [texture]);

  if (texture.uuid) {
    return (
      <primitive
        attach="map"
        object={texture}
        mapping={THREE.EquirectangularReflectionMapping}
        encoding={THREE.sRGBEncoding}
      />
    );
  } else {
    return null;
  }
}

const CustomGallery = (props) => {

  const { custom, galleryImageIndex, setGalleryImageIndex } = props;
  const { gallery } = custom;
  const [imageAspectRatio, setImageAspectRatio] = useState(1);
  const [texture, setTexture] = useState({});
  const group = useRef({});
  

  const galleryMesh = useRef({});
  const galleryTargetRotation = useRef(0);

  const [image, setImage] = useState({});

  useEffect(() => {
    if (gallery) {
      if (gallery[galleryImageIndex]) {
        setImage({ ...gallery[galleryImageIndex] });
      }
    }
  }, [galleryImageIndex, gallery]);

  useEffect(() => {
    if (gallery && gallery[0]) {
      setGalleryImageIndex(0);
      setImage(gallery[0]);
      for (let image of gallery) {
        const img = document.createElement('img');
        img.src = cockpitUrl + image.path;
      }
    }
  }, [gallery, setGalleryImageIndex]);

  useFrame(({ mouse }) => {
    if (group.current?.rotation) {
      group.current.rotation.y = lerp(group.current.rotation.y, mouse.x * Math.PI * 0.125, 0.1);
      group.current.rotation.x = lerp(group.current.rotation.x, -mouse.y * Math.PI * 0.125, 0.1);
    }
  });

  const turn = () => {
    const animate = () => {
      if (galleryMesh.current?.rotation?.y < Math.PI * 2) {
        galleryMesh.current.rotation.y += 0.1;
        requestAnimationFrame(animate);
        
        if (galleryMesh.current.rotation.y >= Math.PI * 0.5) {
          const newGalleryImageIndex = galleryImageIndex < gallery.length - 1 ? galleryImageIndex + 1 : 0;
          if (galleryImageIndex !== newGalleryImageIndex) {
            setGalleryImageIndex(newGalleryImageIndex);
          }
        }
      } else {
        galleryMesh.current.rotation.y = 20;
      }
    }

    requestAnimationFrame(animate);
    galleryTargetRotation.current = Math.PI;
  }

  return (
    <group ref={group} position={[0, 0, 2]}>
      <A11y behavior="button">
        <mesh
          ref={galleryMesh}
          onClick={() => {
            turn();
          }}
        >
          <planeGeometry args={[5, 5 / imageAspectRatio]} />
          <meshPhysicalMaterial
            metalness={0.4}
            side={THREE.DoubleSide}
            needsUpdate={true}
          >
            {
              image?.path &&
              <CustomGalleryTexture
                galleryImageIndex={galleryImageIndex}
                image={image}
                setImageAspectRatio={setImageAspectRatio}
                texture={texture}
                setTexture={setTexture}
              />
            }
          </meshPhysicalMaterial>
        </mesh>
      </A11y>
    </group>
  )
}

export default CustomGallery;
export { CustomGalleryTexture };