import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Footer from '../components/Footer';
import { cockpitUrl } from '../utils/cockpit';
import HomepageSceneCanvas from '../components/HomepageSceneCanvas';

const Homepage = (props) => {

  const { options, windowHeight, image, setImage } = props;
  const { homepageBackgroundImages } = options;

  useEffect(() => {
    if (homepageBackgroundImages && homepageBackgroundImages[0]) {
      setImage(homepageBackgroundImages[Math.floor(Math.random() * homepageBackgroundImages.length)]);
    }
  }, [homepageBackgroundImages, setImage]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="homepage"
      style={{ height: windowHeight + 'px' }}
    >
      <AnimatePresence>
        {
          image.path &&
          <motion.img
            src={cockpitUrl + '/' + image.path}
            alt={image.title}
            className="homepage__background"
          />
        }
      </AnimatePresence>
      <HomepageSceneCanvas {...props} />
      <div className="homepage__footer__wrapper">
        <Footer {...props} />
      </div>
    </motion.div>
  )
}

export default Homepage;