import { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';

const PerspexMaterial = (props) => {
  const { setPerspexMaterial } = props;
  const [envMap, setEnvMap] = useState({});
  const material = useRef({});

  useEffect(() => {
    const newCanvas = document.createElement('canvas');
    newCanvas.width = 256;
    newCanvas.height = 256;
    const ctx = newCanvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 256, 0);

    // Add color stops
    gradient.addColorStop(0, "black");
    gradient.addColorStop(1, "white");

    // Set the fill style and draw a rectangle
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 256, 256);
    const dataUrl = newCanvas.toDataURL();
    new THREE.TextureLoader().load(
      dataUrl, (texture) => {
        texture.mapping = THREE.EquirectangularRefractionMapping;
        setEnvMap(texture);
        setPerspexMaterial && setPerspexMaterial(material.current);
      }
    );
  }, [setPerspexMaterial]);

  return (
    <meshPhysicalMaterial
      ref={material}
      displacementScale={0.0002}
      transmission={0.99}
      reflectivity={0.1}
      color={0xffffff}
      envMapIntensity={0.9}
      ior={1.26}
      thickness={0.1}
      roughness={0}
      metalness={0.0}
      clearCoat={0.99}
      clearcoatRoughness={0}
    >
      {
        envMap.uuid &&
        <primitive attach="envMap" object={envMap} />
      }
    </meshPhysicalMaterial>
  );
};

export default PerspexMaterial;