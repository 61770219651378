import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './Header';
import Preview from '../utils/Preview';
// import HomepageSceneCanvas from './HomepageSceneCanvas';
import Collection from '../views/Collection';
import Homepage from '../views/Homepage';
import Custom from '../views/Custom';
import About from '../views/About';
import Terms from '../views/Terms';
import Collections from '../views/Collections';
import PrivacyPolicy from '../views/PrivacyPolicy';
import { cockpitUrl } from '../utils/cockpit';
// import Cursor from './Cursor';

const Content = (props) => {

  const { location, windowWidth, windowHeight, previewItem, collections, options } = props;

  return (
    <div
      className={`wrapper${options?.blackBackground === true ? ' black-background' : ''}`}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      {
        options.bodyFont &&
        <style>
          {
            `@font-face {
              font-family: 'custom-font';
              src: url('${cockpitUrl + '/' + options.bodyFont}') format('woff');
              font-weight: normal;
              font-style: normal;
            }            
            html, body, p, .font--body {
              font-family: 'custom-font';
            }`
          }
        </style>
      }
      {
        options.headingsFont &&
        <style>
          {
            `@font-face {
              font-family: 'custom-font--heading';
              src: url('${cockpitUrl + '/' + options.headingsFont}') format('woff');
              font-weight: normal;
              font-style: normal;
            }            
            h1, h2, h3, h4, h5, h6, .font--heading {
              font-family: 'custom-font--heading';
            }`
          }
        </style>
      }
      <Header {...props} />
      <Preview {...props} />
      {/* <AnimatePresence>
        {
          (location.pathname === '/' || location.pathname === '/custom') &&
          <motion.div className="canvas__wrapper">
            <HomepageSceneCanvas {...props} />
          </motion.div>
        }
      </AnimatePresence> */}
      {
        !previewItem.collection &&
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route
              path="/about"
              element={<About {...props} />}
            />
            <Route
              path="/terms-and-conditions"
              element={<Terms {...props} />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy {...props} />}
            />
            <Route
              path="/custom"
              element={<Custom {...props} />}
            />
            <Route
              path="/collections"
              element={<Collections {...props} />}
            />
            {
              collections &&
              collections.map(
                (collection, index) => (
                  <Route
                    key={index}
                    path={`/collection/${collection.name_slug}`}
                    element={<Collection {...props} collection={collection} />}
                  />
                )
              )
            }  
            <Route
              path="*"
              element={<Homepage {...props} />}
            />
          </Routes>
        </AnimatePresence>
      }
      {
        previewItem.collection === 'collections' &&
        <Collection {...props} collection={previewItem} isPreview={true} />
      }
      {/* {
        props.userIsTouching === false &&
        <Cursor />
      } */}
    </div>
  )
}

export default Content;