import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import LazyloadImage from '../components/LazyloadImage';
import Footer from '../components/Footer';

const About = (props) => {

  const { about, contact } = props;

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`page page--about`}
    >
      <div className="container-fluid">
        <div className="row page__row">
          <h2 className={`col-xxs-12 page__heading--main`}>About</h2>
        </div>
        <div className="row page__row">
          <div className="col-xxs-12 contact-links">
            {
              contact.email &&
              <a className={`contact-link`} href={`mailto:${contact.email}`} rel="noreferrer" target="_blank">{contact.email}</a>
            }
            {
              contact.instagram &&
              <a className={`contact-link`} href={`https://instagram.com/${contact.instagram}/`} rel="noreferrer" target="_blank">@{contact.instagram}</a>
            }
          </div>
        </div>
        {
          about.image?.path &&
          <div className="row">
            <div className="col-xxs-12">
              <LazyloadImage image={about.image} className="about__image" />
            </div>
          </div>
        }
        {
          about.aboutText &&
          <div className="row">
            <div className="about__text">
              {parse(about.aboutText)}
            </div>
          </div>
        }
      </div>
      <Footer {...props} />
    </motion.main>
  )
}

export default About;